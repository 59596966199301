function sanitize(text) {
  return text
    ? text
        .toString()
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    : text;
}

function escapeRegExp(str) {
  return str && str.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export default {
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    escapedQuery(query) {
      return escapeRegExp(sanitize(query));
    },
    highlight(str) {
      if (Array.isArray(str)) {
        return `${str.map((el) => `${this.highlight(el)}`).join('')}`;
      } else {
        return this.hilghlightPart(str);
      }
    },
    hilghlightPart(str) {
      let res;
      try {
        res = this.getEscapedPart(str);

        return `<strong>${res[0]}</strong>${str
          .toString()
          .replace(res[0], '')
          .replace(new RegExp(res[1] + '$'), '')}<strong>${res[1]}</strong>`.replace('undefined', '');
      } catch (e) {
        return str;
      }
    },
    getEscapedPart(str) {
      const text = sanitize(str);
      if (this.searchQuery.length === 0) {
        return text;
      }
      const query = / /g.test(str) ? this.searchQuery : this.searchQuery.replace(/ /g, '');
      return str.toString().split(new RegExp(this.escapedQuery(query), 'gi'));
    },
  },
};
