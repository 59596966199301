const mapRouterToCartData = (route) => {
  let cartType, componentName;
  switch (route) {
    case 'quickquote':
      cartType = 'Quote';
      componentName = 'Quote';
      break;
    case 'artproof':
      cartType = 'Proof';
      componentName = 'Artproof';
      break;
    case 'sample':
      cartType = 'Sample';
      componentName = 'Sample';
      break;
    case 'designer':
      cartType = 'Cart';
      componentName = 'DesignProduct';
      break;
    case 'configureproduct':
      cartType = 'Cart';
      componentName = 'ConfigureProduct';
      break;
    default:
      cartType = null;
      componentName = null;
      break;
  }
  let loaderName;
  if (route === 'configureproduct') {
    loaderName = 'Configure';
  } else if (route === 'designer') {
    loaderName = 'Design';
  } else loaderName = cartType;
  return { loaderName, cartType, componentName }
}

const mapButtonNamesToRoutes = (name) => {
  let route;
  switch (name) {
    case 'Quote Request':
      route = 'quickquote';
      break;
    case 'Proof Request':
      route = 'artproof';
      break;
    case 'Design & Buy':
      route = 'designer'
      break;
    case 'Configure':
      route = 'configureproduct';
      break;
    default:
      break;
  }
  return route;
}

export { mapRouterToCartData, mapButtonNamesToRoutes }
