
  import createOrderRequestMixin from '@/plugins/createOrderRequestMixin';
  import { mapButtonNamesToRoutes, mapRouterToCartData } from '../../utils/carts';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: {
      Quote: () => import('../quote/Quote1.vue'),
      Proof: () => import('../artproof/VirtualArtproof1.vue'),
      Sample: () => import('../sample/SampleRequest1.vue'),
      Design: () => import('../designer/DesignProduct1.vue'),
      Configure: () => import('../configureproduct/ConfigureProduct1.vue'),
      Button: () => import('./ProductCardButton.vue'),
    },
    data() {
      return {
        buttonClass: 'btn btn-primary w-100 mb-2',
        addProductModalComponent: null,
        addProductModalTitle: null,
        loadCartPageDataQuote: false,
        loadCartPageDataProof: false,
        loadCartPageDataSample: false,
        loadCartPageDataConfigure: false,
        loadCartPageDataDesign: false,
        loadCartPageDataBox: false,
        addingToCart: false,
        loadOnInit: false,
        cartModalQuery: {},
        pageParams: {},
        productView: null,
      };
    },
    mixins: [createOrderRequestMixin],
    props: {
      productSlug: String,
      displayedButtons: {
        type: Object,
        default: () => ({
          showDesign: false,
          showCustomize: false,
          showAddToCart: false,
          showProofRequest: false,
          showQuoteRequest: false,
          showAddToBox: false,
        }),
      },
    },
    created() {
      this.clearSelectedParts();
    },
    computed: {
      ...mapGetters({
        boxSideBarVisible: 'sidebars/boxSideBarVisible',
        isDatePassed: 'isDatePassed',
      }),
    },
    methods: {
      ...mapMutations({
        addUnsavedBoxItem: 'cart/addUnsavedBoxItem',
        toggleBoxSidebar: 'sidebars/TOGGLE_BOX_SIDEBAR',
        toggleCartSidebar: 'sidebars/TOGGLE_CART_SIDEBAR',
      }),
      closeAddProductModal() {
        this.addProductModalComponent = null;
        this.addProductModalTitle = null;
      },
      async setProductData() {
        this.productData = await this.$api.products.getProductBySlug(this.productSlug, {});
        await this.loadProductData();
        const { storeUrl } = this.storeInformation;
        const { pageData } = await this.$api.stores.getStorePageDetails(
          {
            storeUrl,
            urlSlug: 'product',
            pageDataReq: this.$route.query,
            pageHeadTagReq: {
              additionalSlug: this.$route.params.slug,
              query: {},
              canonicalPath: 'products',
            },
          },
          this.$axios
        );
        this.productView = pageData.find((i) => i.name.includes('ProductView')).props.productView;
        this.onLoadProduct();
      },
      async addToBox() {
        try {
          this.loadCartPageDataBox = true;
          await this.setProductData();
          if (!this.boxSideBarVisible) {
            this.toggleBoxSidebar();
          }
          this.addUnsavedBoxItem({
            productData: this.productData,
            orderDataWithCustomer: this.orderData,
            orderData: {},
            selectedPart: this.selectedPart,
            selectedDecoration: this.selectedDecoration,
            qtyPerBox: 1,
          });
        } catch (e) {
          console.log(e);
        } finally {
          this.loadCartPageDataBox = false;
        }
      },
      async addToCart() {
        try {
          this.addingToCart = true;
          await this.setProductData();
          await this.createCartOrder();
        } catch (e) {
          this.notificateError(e.message || e);
          console.log(e);
        } finally {
          this.addingToCart = false;
        }
      },
      async addProduct(name) {
        const route = mapButtonNamesToRoutes(name);
        const { loaderName, componentName, cartType } = mapRouterToCartData(route);
        const { carts = [] } = this.storeInformation;
        const storeCart = carts.find((i) => i.type === cartType);
        if (storeCart && storeCart.openModalWhenAdding) {
          this[`loadCartPageData${loaderName}`] = true;
          const { storeUrl } = this.storeInformation;
          const pageData = (await this.$api.stores.getStoreSlugPage({ storeUrl, urlSlug: route })).data;
          const component = pageData.find((i) => i.name.includes(componentName));
          let cartModalType;
          let cartModalTypeTitle = cartType;
          if (cartType === 'Quote') {
            this.pageParams = { quote: component.props.quickquote };
            cartModalTypeTitle = `${cartModalTypeTitle} Request`;
          } else if (cartType === 'Proof') {
            this.pageParams = { artproof: component.props.artproof };
            cartModalTypeTitle = `${cartModalTypeTitle} Request`;
          } else if (cartType === 'Sample') {
            cartModalType = 'Sample';
            this.pageParams = { sample: component.props.sample };
            cartModalTypeTitle = `${cartModalTypeTitle} Request`;
          } else if (cartType === 'Cart') {
            this.pageParams = { configureProduct: component.props.configureProduct };
          }
          this.cartModalQuery = {};
          this.cartModalQuery.cartModalType = cartModalType;
          this.addProductModalComponent = loaderName;
          this.addProductModalTitle = cartModalTypeTitle;
          this.productData = await this.$api.products.getProductBySlug(this.productSlug, {});
          this.$refs.addProductModal.show();
          this[`loadCartPageData${loaderName}`] = false;
        } else {
          this.$router.push({
            path: `/${route}/${this.productSlug}`,
            query: {},
          });
        }
      },
      confirmCartModal(cartType) {
        this.$refs.addProductModal.hide();
        this.toggleCartSidebar(cartType);
      },
    },
  };
